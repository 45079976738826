import {
  Footer,
  Header,
  Main,
  SlotsProvider,
  useSlotProps
} from '@diallink-corp/convergo-react-layout';
import { Heading, Paragraph } from '@diallink-corp/convergo-react-text';
import { StyleProps } from '@diallink-corp/convergo-types';
import { useNavigate } from '@remix-run/react';
import { PropsWithChildren } from 'react';
import { useTranslation } from 'react-i18next';

import { ButtonLink } from '../Links/ButtonLink';
import { TextLink } from '../Links/TextLink';

interface IllustratedBoundaryProps {
  /** The image src of the illustration. */
  illustrationSrc: string;
  /** The alt text of the illustration. */
  illustrationAlt: string;
  /** The heading of the boundary. */
  heading: string;
  /** The sub heading of the boundary. */
  subheading: string;
  /** The description of the boundary. */
  description?: string;
  /**
   * The variant of the boundary.
   *
   * @default main
   */
  variant?: 'main' | 'container';
}

export function IllustratedBoundary(props: IllustratedBoundaryProps) {
  const { t } = useTranslation();

  const { heading, subheading, description, variant = 'main' } = props;

  const navigate = useNavigate();

  const slots = {
    main: {
      boundary: {
        className: `flex flex-col justify-evenly h-full gap-y-200 bg-[url('/images/error-boundary-background.svg')] bg-cover bg-repeat p-200 sm:flex-row sm:gap-y-0 sm:gap-x-400 sm:items-center`
      },
      illustration: {
        className:
          'flex items-center justify-center py-400 overflow-hidden sm:h-full'
      },
      header: {
        className: `flex flex-col space-y-100 text-center sm:text-start`
      },
      heading: {
        size: '2xlarge',
        level: 1
      },
      paragraph: {
        size: 'medium',
        className: 'text-gray-400'
      },
      footer: {
        className: 'w-full text-center space-x-200 sm:text-start'
      }
    },
    container: {
      boundary: {
        className: `flex flex-col justify-evenly items-center h-full gap-y-200 bg-[url('/images/error-boundary-background.svg')] bg-cover bg-repeat p-200 @md:flex-row @md:gap-y-0 @md:gap-x-400`
      },
      illustration: {
        className:
          'flex items-center justify-center py-400 overflow-hidden @md:h-full'
      },
      header: {
        className: `flex flex-col space-y-100 text-center @md:text-start`
      },
      heading: {
        size: 'xlarge',
        level: 1
      },
      paragraph: {
        size: 'small',
        className: 'text-gray-400'
      },
      footer: {
        className: 'hidden w-full text-center space-x-200 @md:text-start'
      }
    }
  };

  return (
    <SlotsProvider slots={slots[variant]}>
      <Boundary variant={variant}>
        <Illustration {...props} />
        <div className="space-y-100">
          <Header>
            <Heading>{heading}</Heading>
            <Paragraph>{subheading}</Paragraph>
            {description && <Paragraph>{description}</Paragraph>}
          </Header>
          <Footer>
            <ButtonLink onPress={() => navigate(-1)}>{t('go-back')}</ButtonLink>
            <TextLink to="/support">{t('contact-support')}</TextLink>
            <TextLink to="/auth/logout">{t('log-out')}</TextLink>
          </Footer>
        </div>
      </Boundary>
    </SlotsProvider>
  );
}

interface BoundaryProps extends StyleProps, PropsWithChildren {
  /**
   * The variant of the boundary.
   *
   * @default main
   */
  variant: 'main' | 'container';
}

function Boundary(props: BoundaryProps) {
  const { children, className, variant } = useSlotProps('boundary', props);

  return (
    <>
      {variant === 'main' ? (
        <Main className={className}>{children}</Main>
      ) : (
        <div className="@container h-full">
          <div className={className}>{children}</div>
        </div>
      )}
    </>
  );
}

interface IllustrationProps extends StyleProps {
  /** The image src of the illustration. */
  illustrationSrc: string;
  /** The alt text of the illustration. */
  illustrationAlt: string;
}

function Illustration(props: IllustrationProps) {
  const { className, illustrationAlt, illustrationSrc } = useSlotProps(
    'illustration',
    props
  );

  return (
    <div className={className}>
      <img
        alt={illustrationAlt}
        src={illustrationSrc}
        className="max-h-[85%]"
      />
    </div>
  );
}
